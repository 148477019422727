var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm._m(0), _c("div", {
    staticClass: "esg-box"
  }, [_c("div", {
    staticClass: "esg-box_header animate__animated animate__fadeInLeft animate__slow"
  }, _vm._l(_vm.esgList[_vm.language - 1], function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "esg-box_header_item",
      on: {
        click: function ($event) {
          return _vm.handleClick(item);
        }
      }
    }, [_c("img", {
      attrs: {
        src: item.img,
        alt: ""
      }
    }), _c("div", {
      staticClass: "esg-box_header_item_title",
      class: _vm.esgModel === item.title ? "light" : "model"
    }, [_vm._v(_vm._s(item.title) + " ")]), _vm.esgModel === item.title ? _c("div", {
      staticClass: "esg-box_header_item_line"
    }) : _vm._e()]);
  }), 0), _c("div", {
    staticClass: "esg-box_content"
  }, [_c(_vm.currentPage, {
    tag: "component"
  })], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "esg-header"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/esg/header.png"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };