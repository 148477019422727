import HeaderTitle from './title.vue';
import { mapState } from 'vuex';
import { esgReportText } from '../config/index';
export default {
  components: {
    HeaderTitle
  },
  data() {
    return {
      esgReportText,
      reportList: [],
      title: this.$api.api_title
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  mounted() {
    this.getReportList();
  },
  methods: {
    getReportList() {
      this.$http.get(this.$api.esg_reportList).then(res => {
        if (res.code == 0 && res.data) {
          this.reportList = res.data;
        }
      });
    },
    viewPdf(link) {
      if (link === '/investpdf/null.pdf') return;
      window.open(this.title + link, '_blank');
    }
  }
};