var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "module-box animate__animated animate__fadeInUp animate__slow"
  }, [_c("header-title", {
    attrs: {
      title: _vm.esgReportText.esgReportTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "esg-report"
  }, _vm._l(_vm.reportList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "esg-report_item"
    }, [_c("div", {
      staticClass: "esg-report_item_img"
    }, [item.reportImage && item.reportImage !== "/uploadImage/" ? _c("img", {
      attrs: {
        src: _vm.title + item.reportImage,
        alt: ""
      }
    }) : _c("div", {
      staticClass: "esg-report_item_img_empty"
    }, [_vm._v("即将上线")])]), _c("div", {
      staticClass: "esg-report_item_title",
      on: {
        click: function ($event) {
          return _vm.viewPdf(item.reportUrl);
        }
      }
    }, [_vm._v(" " + _vm._s(item.reportTitle) + " ")])]);
  }), 0)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };