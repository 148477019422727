import SocialResponsibility from './components/socialResponsibility.vue';
import GreenDevelopment from './components/greenDevelopment.vue';
import SpecsGovern from './components/specsGovern.vue';
import RecognitionHonors from './components/recognitionHonors.vue';
import EsgReport from './components/esgReport.vue';
import { esgList } from './config/index';
import { mapState } from 'vuex';
export default {
  components: {
    SocialResponsibility,
    GreenDevelopment,
    SpecsGovern,
    RecognitionHonors,
    EsgReport
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  data() {
    return {
      esgList,
      esgModel: '',
      currentPage: ''
    };
  },
  mounted() {
    this.esgModel = this.esgList[this.language - 1][0].title;
    this.currentPage = this.esgList[this.language - 1][0].components;
  },
  methods: {
    handleClick(item) {
      this.esgModel = item.title;
      this.currentPage = item.components;
    }
  }
};