var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "module-box animate__animated animate__fadeInUp animate__slow"
  }, [_c("header-title", {
    attrs: {
      title: _vm.socialResponsibilityText.researchInnovationTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "research-innovation"
  }, [_c("div", {
    staticClass: "research-innovation_text",
    domProps: {
      innerHTML: _vm._s(_vm.socialResponsibilityText.researchInnovation[_vm.language - 1])
    }
  }), _c("img", {
    attrs: {
      src: require("../../../assets/esg/researchInnovation2.png"),
      alt: ""
    }
  })]), _c("header-title", {
    attrs: {
      title: _vm.socialResponsibilityText.qualityManagementTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "quality-management",
    domProps: {
      innerHTML: _vm._s(_vm.socialResponsibilityText.qualityManagement[_vm.language - 1])
    }
  }), _c("header-title", {
    attrs: {
      title: _vm.socialResponsibilityText.customerServiceTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "customer-service"
  }, _vm._l(_vm.socialResponsibilityText.customerService[_vm.language - 1], function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "customer-service_item"
    }, [_c("div", {
      staticClass: "customer-service_item_left"
    }, [_c("img", {
      attrs: {
        src: item.img,
        alt: ""
      }
    }), _c("div", {
      staticClass: "customer-service_item_left_text"
    }, [_vm._v(_vm._s(item.title))])]), _c("div", {
      staticClass: "customer-service_item_right",
      domProps: {
        innerHTML: _vm._s(item.text)
      }
    })]);
  }), 0), _c("header-title", {
    attrs: {
      title: _vm.socialResponsibilityText.dutyProcureTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "duty-procure"
  }, _vm._l(_vm.socialResponsibilityText.dutyProcure[_vm.language - 1], function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "duty-procure_item"
    }, [_c("div", {
      staticClass: "duty-procure_item_title"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "duty-procure_item_text",
      domProps: {
        innerHTML: _vm._s(item.text)
      }
    })]);
  }), 0), _c("header-title", {
    attrs: {
      title: _vm.socialResponsibilityText.staffEquityWelfareTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "staff-equity-welfare"
  }, _vm._l(_vm.socialResponsibilityText.staffEquityWelfare[_vm.language - 1], function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "staff-equity-welfare_item"
    }, [_c("div", {
      staticClass: "staff-equity-welfare_item_title"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "staff-equity-welfare_item_text",
      domProps: {
        innerHTML: _vm._s(item.text)
      }
    })]);
  }), 0), _c("header-title", {
    attrs: {
      title: _vm.socialResponsibilityText.healthSafeTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "health-safe"
  }, [_c("div", {
    staticClass: "health-safe_item"
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/esg/healthSafe.png"),
      alt: "absmiddle"
    }
  }), _c("div", {
    staticClass: "health-safe_item_text",
    staticStyle: {
      "margin-left": "16px"
    },
    domProps: {
      innerHTML: _vm._s(_vm.socialResponsibilityText.healthSafe[_vm.language - 1][0])
    }
  })]), _c("div", {
    staticClass: "health-safe_item"
  }, [_c("div", {
    staticClass: "health-safe_item_text",
    staticStyle: {
      "margin-right": "16px"
    },
    domProps: {
      innerHTML: _vm._s(_vm.socialResponsibilityText.healthSafe[_vm.language - 1][1])
    }
  }), _c("img", {
    attrs: {
      src: require("../../../assets/esg/dangerChemical.png"),
      alt: "absmiddle"
    }
  })])]), _c("header-title", {
    attrs: {
      title: _vm.socialResponsibilityText.developContinueText[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "develop-continue"
  }, [_c("div", {
    staticClass: "develop-continue_item"
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/esg/training.jpg"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "develop-continue_item_title"
  }, [_vm._v(_vm._s(_vm.socialResponsibilityText.developContinue[_vm.language - 1][0].title) + " ")]), _c("div", {
    staticClass: "develop-continue_item_text",
    domProps: {
      innerHTML: _vm._s(_vm.socialResponsibilityText.developContinue[_vm.language - 1][0].text)
    }
  })]), _c("div", {
    staticClass: "develop-continue_item",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/esg/training.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "develop-continue_item_title"
  }, [_vm._v(_vm._s(_vm.socialResponsibilityText.developContinue[_vm.language - 1][1].title) + " ")]), _c("div", {
    staticClass: "develop-continue_item_text",
    staticStyle: {
      "padding-top": "1.5rem"
    },
    domProps: {
      innerHTML: _vm._s(_vm.socialResponsibilityText.developContinue[_vm.language - 1][1].text)
    }
  })]), _c("div", {
    staticClass: "develop-continue_item",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", {
    staticClass: "develop-continue_item_title"
  }, [_vm._v(_vm._s(_vm.socialResponsibilityText.developContinue[_vm.language - 1][2].title) + " ")]), _c("div", {
    staticClass: "develop-continue_item_text",
    domProps: {
      innerHTML: _vm._s(_vm.socialResponsibilityText.developContinue[_vm.language - 1][2].text)
    }
  })])]), _c("header-title", {
    attrs: {
      title: _vm.socialResponsibilityText.medicalEcologyHealthTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "medical-ecology-health"
  }, [_vm._l(_vm.socialResponsibilityText.medicalEcologyHealthText.slice(0, 3), function (item) {
    return _c("div", {
      key: item.title
    }, [_c("div", {
      staticClass: "medical-ecology-health_title"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "medical-ecology-health_text"
    }, [_vm._v(_vm._s(item.content))])]);
  }), _vm._l(_vm.medicalTextList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "medical-ecology-health_item"
    }, [_c("div", {
      staticClass: "medical-ecology-health_item_title"
    }, [_vm._v(_vm._s(item.medicalTitle))]), _c("div", {
      staticClass: "medical-ecology-health_item_text"
    }, [_c("div", {
      staticClass: "medical-ecology-health_item_text_f"
    }, [_vm._v(" > ")]), _c("div", {
      domProps: {
        innerHTML: _vm._s(item.medicalText)
      }
    })])]);
  }), _vm._l(_vm.socialResponsibilityText.medicalEcologyHealthText.slice(3, 4), function (item) {
    return _c("div", {
      key: item.title,
      staticStyle: {
        "margin-top": "0.5rem"
      }
    }, [_c("div", {
      staticClass: "medical-ecology-health_title"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "medical-ecology-health_text"
    }, [_vm._v(_vm._s(item.content))])]);
  }), _c("img", {
    staticClass: "medical-ecology-health_img",
    attrs: {
      src: require("../../../assets/esg/medicalEcologyHealth.png"),
      alt: ""
    }
  })], 2), _c("header-title", {
    attrs: {
      title: _vm.socialResponsibilityText.policyDownTitle[_vm.language - 1]
    }
  }), _c("div", {
    staticClass: "policy-down"
  }, _vm._l(_vm.policyList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "policy-down_item"
    }, [_c("img", {
      staticClass: "policy-down_item_icon",
      attrs: {
        src: require("../../../assets/esg/download.png")
      }
    }), _c("el-link", {
      attrs: {
        type: "success"
      }
    }, [_c("span", {
      staticClass: "policy-down_item_text",
      on: {
        click: function ($event) {
          return _vm.viewPdf(item.honorUrl);
        }
      }
    }, [_vm._v(_vm._s(item.honorPic))])])], 1);
  }), 0)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };