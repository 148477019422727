import "core-js/modules/es.array.push.js";
import HeaderTitle from './title.vue';
import { mapState } from 'vuex';
import { specsGovernText } from '../config/index';
export default {
  components: {
    HeaderTitle
  },
  data() {
    return {
      specsGovernText,
      policyList: [],
      title: this.$api.api_title
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  mounted() {
    this.getPolicyList();
  },
  methods: {
    getPolicyList() {
      this.$http.get(this.$api.esg_honorList).then(res => {
        if (res.code == 0 && res.data) {
          res.data.forEach(item => {
            if (item.honorType == 3) this.policyList.push(item);
          });
        }
      });
    },
    viewPdf(link) {
      window.open(this.title + link + '#toolbar=0', '_blank');
    }
  }
};